/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 React themes
import theme from "assets/theme";

// Material Kit 2 React routes
// import routes from "routes";
import SignInBasic from "pages/LandingPages/SignIn";
import GPTPage from "pages/LandingPages/GPTPage";

export default function App() {
  const { pathname } = useLocation();
  localStorage.setItem("password", "574628696");
  if (localStorage.getItem("validationDat") != null) {
    localStorage.setItem("validationDate", "1970-01-01T00:00:00Z");
  }

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        <Route path="/gpt" element={<GPTPage />} />
        <Route path="" element={<SignInBasic />} />
        <Route path="*" element={<Navigate to="" />} />
      </Routes>
    </ThemeProvider>
  );
}
