/* eslint-disable no-unused-vars */
/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { useNavigate } from "react-router-dom";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
import React from "react";
import { ClipLoader } from "react-spinners";
import { styled } from "@mui/material/styles";
import LinearProgress from "components/MKProgress";

const RedInputField = styled(MKInput)(() => ({
  "& .MuiInput-root:after": {
    borderColor: "#c60c0e",
  },
}));

const DEFAULT_PARAMS_DAVINCI = {
  model: "gpt-4",
  temperature: 0.5,
  max_tokens: 2000,
  top_p: 1.0,
  frequency_penalty: 0.0,
  presence_penalty: 0.0,
  prompt: "Give me three dog names",
};

const DEFAULT_PARAMS = {
  model: "gpt-4",
  max_tokens: 2000,
  messages: [
    { role: "user", content: "Tell the world about the ChatGPT API in the style of a pirate." },
  ],
};

// eslint-disable-next-line no-unused-vars
const basePrompts = {
  SEO_p1:
    "Please ignore all previous instructions. I want you to respond only in German. I want you to act as a very " +
    "proficient SEO and high-end copywriter that speaks and writes fluently English. I want you to pretend that you" +
    " can write content so well in English that it can outrank other articles. Your task is to write an article" +
    " starting with SEO Title with a bold letter, meta description, introduction and rewrite the content and include" +
    " subheadings using the following keywords separated by semicolons: ",
  SEO_p2:
    ". Set the title and subheadings in their respective html tags and add a new line before and after them. The H1 tag " +
    "should be 2rem big and h2 tag 1.5rem and both should have 15px margin bottom and top. " +
    "The meta description should be in a p tag, start with 'Meta Description: ', bold, followed by a new line and 5px margin bottom" +
    " to make it more distinguishable from the rest. Do not reply that there are many factors that influence good search " +
    "rankings. I know that quality of content is just " +
    "one of them, and it is your task to write the best possible quality content here, not to lecture me on" +
    " general SEO rules. Write a fully markdown formatted article in German. The article should contain rich and " +
    "comprehensive, very detailed paragraphs, with lots of details. All output shall be in German. Do use useful" +
    " subheadings with keyword-rich titles. Get to the point precisely and accurate. Do not explain what and why," +
    " just give me your best possible article. The text to rewrite is this: ",
  SEO_min_p1:
    "Please ignore all previous instructions. I want you to respond only in German. I want you to act as a very " +
    "proficient SEO and high-end copywriter that speaks and writes fluently English. I want you to pretend that you" +
    " can write content so well in English that it can outrank other articles. Your task is to write 3 SEO Titles," +
    " 3 meta descriptions, and 3 subheadings using the text in square brackets and the following keywords separated by semicolons: ",
  SEO_min_p2:
    ". Set the title and subheadings in their respective html tags and add a new line before and after them. The H1 tag " +
    "should be 2rem big and h2 tag 1.5rem and both should have 15px margin bottom and top. " +
    "The h2 tag should have a text color of #23395d. The meta description should be in a p tag with black text color, " +
    "start with 'Meta Description: ', bold, followed by a new line and 5px margin bottom" +
    " to make it more distinguishable from the rest. Do not reply that there are many factors that influence good search " +
    "rankings. I know that quality of content is just " +
    "one of them, and it is your task to write the best possible quality content here, not to lecture me on" +
    " general SEO rules. All output shall be in German. Do use useful" +
    " subheadings with keyword-rich titles. The text to rewrite is this: ",
  Simplify_p1: "Please ignore all previous instructions. I want you to respond only in German. ",
  Simplify_p2:
    "Under no circumstances change the meaning of anything that is written." +
    "Do not echo my prompt. Do not remind me what I asked you for. Do not apologize. Do not self-reference. Do not " +
    "explain what and why. All output shall be in German.",
  Grammar:
    "Please ignore all previous instructions. I want you to respond only in language German. I want you to act as a very" +
    " proficient proofreader that speaks and writes fluent German. Do not reply that there are many factors that " +
    "influence good proofreaders. I want you to correct the spelling, grammar and punctuation of the following text " +
    "enclosed by square brackets in German.The text" +
    "must stay the same, only changing the spelling, grammar and punctuation mistakes. Do not change the wording of the text.  " +
    "Do not change anything that is already correct. If another wording sounds better, but both are correct, do not change it." +
    "And never never never change anything that is enclosed by quotes. Do not echo my prompt. " +
    "Do not remind me what I asked you for. " +
    "Do not apologize. Do not self-reference. All output shall be in German. " +
    "Never change the semantical meaning of the text in any way. " +
    "Furthermore replace all newlines in the text with an ' <br>'. Make sure that the text is always in the exact same format as in the input. Make sure to never miss any newlines." +
    "Compare your text with my text. If you change the spelling of any word or the grammar of a sentence, put that word and only that word in a <FONT COLOR=red> tag." +
    "Only put them in the tag if absolutely necessary, but always put the complete word in the tags. Never put any words in the tags thar are similar to the original text. Make 100% sure that the words in the tag are different from the original Text.",
};

// eslint-disable-next-line no-unused-vars
const fleschLevel = {
  one: "75-80",
  two: "75-80",
  three: "80-85",
  four: "85-90",
  five: "95-100",
};

// eslint-disable-next-line no-unused-vars
const audienceLevel = {
  one: "I want you to simplify the text in square brackets. ",
  two:
    "Simplify the following text by removing any complex language or jargon. Your target audience is " +
    "individuals with a reading level of 8th grade or below. Use simple sentences with a maximum of 15 words each. " +
    "Aim for a Gunning-Fog index of 10 or lower. ",
  three:
    "Please rewrite the information below in at least 90 words and at most 150 words in a simplified style, using short sentences and common vocabulary. " +
    "Your target audience is individuals with a reading level of 8th grade or below. I don't want a text that is " +
    "shorter than 90 words. The word count is very important. Use simple sentence " +
    "structures such as subject-verb-object and aim for an average sentence length of 10 words or fewer. " +
    "Aim for a Flesch-Kincaid readability score of 80 or higher. ",
  four:
    "Please provide a brief overview of the text, highlighting the main points and using clear language. " +
    "Your target audience is individuals with a reading level of 10th grade or below. Use simple sentence " +
    "structures such as subject-verb-object and aim for an average sentence length of 15 words or fewer. " +
    "Aim for a Flesch-Kincaid readability score of 80 or higher.",
  five:
    "Please simplify the text below so that it is easy to understand. Your target audience is individuals with a " +
    "reading level of 5th grade or below. Use short, simple sentences with a maximum of 10 words each and use only " +
    "extremely simple language. Aim for a Flesch-Kincaid readability score of 80 or higher. ",
};

// eslint-disable-next-line react/prop-types
function GPTInterface({ steps, simplifyLevel = 1, seoKeywords = [] }) {
  const [loading, setLoading] = React.useState(false);

  const [progress, setProgress] = React.useState(0);

  const lastValidated = new Date(localStorage.getItem("validationDate"));
  let simplifyPrompt = "";
  const navigate = useNavigate();
  if (simplifyLevel === 1) {
    simplifyPrompt = `${basePrompts.Simplify_p1}${audienceLevel.one}${basePrompts.Simplify_p2}`;
  } else if (simplifyLevel === 2) {
    simplifyPrompt = `${basePrompts.Simplify_p1}${audienceLevel.two}${basePrompts.Simplify_p2}`;
  } else if (simplifyLevel === 3) {
    simplifyPrompt = `${basePrompts.Simplify_p1}${audienceLevel.three}${basePrompts.Simplify_p2}`;
  } else if (simplifyLevel === 4) {
    simplifyPrompt = `${basePrompts.Simplify_p1}${audienceLevel.four}${basePrompts.Simplify_p2}`;
  } else if (simplifyLevel === 5) {
    simplifyPrompt = `${basePrompts.Simplify_p1}${audienceLevel.five}${basePrompts.Simplify_p2}`;
  }
  if (new Date(Date.now()) - lastValidated < 30 / 60000) {
    navigate("/");
  }

  // const [prompt, setPrompt] = React.useState("");
  const [text, setText] = React.useState("");

  // eslint-disable-next-line no-unused-vars
  const [finalResult, setFinalResult] = React.useState("");

  const [errorMessage, setErrorMessage] = React.useState("");

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  const spinner = (
    // Center Cliploader in element
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: "40%",
          width: "20%",
        }}
      >
        <ClipLoader color="black" size={150} />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: "20%",
          marginTop: "10%",
          width: "60%",
        }}
      >
        <LinearProgress variant="determinate" value={progress} />
      </div>
    </>
  );

  async function chatGptApiCall(promptInput, step) {
    // const filteredInput = promptInput.replace(/(\r\n|\n|\r)/gm, " ");
    const filteredInput = promptInput;
    const key = "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";
    // eslint-disable-next-line no-underscore-dangle
    let temperature_ = 0.5;
    if (step === "SEO") {
      temperature_ = 0.5;
    }
    if (step === "Simplify") {
      temperature_ = 0.3;
    }
    if (step === "Grammar") {
      temperature_ = 0.0;
    }
    const params = {
      ...DEFAULT_PARAMS,
      messages: [{ role: "user", content: filteredInput }],
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer ".concat(String(key)),
      },
      body: JSON.stringify(params),
    };
    console.log(params);
    setLoading(true);
    return fetch("https://api.openai.com/v1/chat/completions", requestOptions);
  }

  function modifyPrompt(step, prompt) {
    if (step === "SEO") {
      console.log(`${basePrompts.SEO_min_p1}${seoKeywords}${basePrompts.SEO_min_p2} [${prompt}]`);
      return `${basePrompts.SEO_min_p1}${seoKeywords}${basePrompts.SEO_min_p2} [${prompt}]`;
    }
    if (step === "Simplify") {
      return `${simplifyPrompt} [${prompt}]`;
    }
    if (step === "Grammar") {
      console.log(`${basePrompts.Grammar} [${prompt}]`);
      return `${basePrompts.Grammar} [${prompt}]`;
    }
    return "";
  }

  async function feedChatGPT() {
    // console.log("onClick");
    // console.log(text);

    try {
      // eslint-disable-next-line react/prop-types
      const stepKeys = Object.keys(steps).filter((step) => steps[step]);
      if (stepKeys.length === 0) {
        setFinalResult("");
        setErrorMessage("Please select at least one step");
        setLoading(false);
        return;
      }
      setErrorMessage("");
      let stepIndex = 0;
      setProgress(((stepIndex + 1) / (stepKeys.length + 1)) * 100);
      let prompt = modifyPrompt(stepKeys[stepIndex], text);
      await chatGptApiCall(prompt, stepKeys[stepIndex]).then(async (response) => {
        await response.json().then((data) => {
          prompt = data.choices[0].message.content;
          // console.log(prompt);
        });
      });
      if (stepIndex >= stepKeys.length - 1) {
        setLoading(false);
        if (stepKeys[stepIndex] === "Grammar") {
          setFinalResult(prompt);
        } else {
          setFinalResult(prompt);
        }
        return;
      }
      // eslint-disable-next-line no-plusplus
      stepIndex++;
      setProgress(((stepIndex + 1) / (stepKeys.length + 1)) * 100);
      prompt = modifyPrompt(stepKeys[stepIndex], prompt);
      await chatGptApiCall(prompt, stepKeys[stepIndex]).then(async (response) => {
        await response.json().then((data) => {
          prompt = data.choices[0].text;
        });
      });
      if (stepIndex >= stepKeys.length - 1) {
        setLoading(false);
        setFinalResult(prompt);
        return;
      }
      // eslint-disable-next-line no-plusplus
      stepIndex++;
      setProgress(((stepIndex + 1) / (stepKeys.length + 1)) * 100);
      prompt = modifyPrompt(stepKeys[stepIndex], prompt);
      await chatGptApiCall(prompt, stepKeys[stepIndex]).then(async (response) => {
        await response.json().then((data) => {
          prompt = data.choices[0].text;
        });
      });
      setLoading(false);
      setFinalResult(prompt);
      return;
    } catch (error) {
      console.log(error);
    }
    console.log(finalResult);
  }

  localStorage.setItem("validationDate", new Date(Date.now()).toString());

  return (
    <MKBox component="section" py={{ xs: 0, lg: 6 }}>
      <Container>
        <Grid item xs={12} lg={5}>
          <MKBox component="form" p={2} method="post">
            <MKBox pt={0.5} pb={3} px={3}>
              <Grid container>
                <Grid item xs={12} pr={1} mb={6}>
                  <RedInputField
                    variant="standard"
                    placeholder="Insert your text here"
                    InputLabelProps={{ shrink: true, style: { borderColor: "#123456" } }}
                    fullWidth
                    multiline
                    rows={20}
                    onChange={handleTextChange}
                    size="large"
                    sx={{
                      borderColor: "#123456",
                    }}
                  />
                </Grid>
              </Grid>
              <MKBox
                minHeight="2.5rem"
                width="100%"
                sx={{
                  display: "grid",
                  placeItems: "center",
                  gap: 1,
                  paddingLeft: "5%",
                  paddingRight: "5%",
                  paddingBottom: "2rem",
                  //  display: isVisible ? "grid" : "none",
                }}
              >
                {errorMessage.length > 1 && (
                  <MKBox
                    sx={{
                      color: "red",
                      fontSize: "0.8rem",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {errorMessage}
                  </MKBox>
                )}
                <MKButton
                  color="kleineZeitungRed"
                  onClick={() => feedChatGPT()}
                  sx={{
                    borderRadius: "10rem",
                    width: "12.5rem",
                    height: "3rem",
                    fontSize: "1rem",
                  }}
                >
                  Submit
                </MKButton>
              </MKBox>
            </MKBox>
          </MKBox>
        </Grid>
        <Grid item xs={7} lg={5}>
          <MKBox component="form" p={2} method="post">
            <MKBox px={3} py={{ xs: 4, sm: 8 }}>
              <MKTypography component="span" variant="body2" color="text" mb={2}>
                {/* eslint-disable-next-line react/no-danger */}
                {loading ? spinner : <div dangerouslySetInnerHTML={{ __html: finalResult }} />}
              </MKTypography>
            </MKBox>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default GPTInterface;
