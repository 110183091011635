/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// GPTPage page sections
import GPTInterface from "pages/LandingPages/GPTPage/sections/GPTInterfaceNew";
import Footer from "pages/LandingPages/GPTPage/sections/Footer";
// Routes
// import routes from "routes";

// Images
// import bgImage from "assets/images/city-profile.jpg";
// import Tabs from "@mui/material/Tabs";
// import Tab from "@mui/material/Tab";
import { alpha, styled } from "@mui/material/styles";
import React from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Navigate } from "react-router-dom";

// import { SpaceBar } from "@mui/icons-material";
// import { Grid } from "@mui/material";

const RedSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    "&:hover": {
      backgroundColor: alpha("#c60c0e", theme.palette.action.hoverOpacity),
    },
  },
  "& .Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#c60c0e80!important",
    border: "none",
  },
  "& .Mui-checked .MuiSwitch-thumb": {
    border: "none",
    backgroundColor: "#c60c0e",
  },
}));

const RedSlider = styled(Slider)(() => ({
  "& .MuiSlider-thumb": {
    backgroundColor: "#c60c0e",
    width: "1.25rem",
    height: "1.25rem",
    border: "none",
  },
  "& .MuiSlider-thumb:active": {
    boxShadow: "0rem 0rem 0rem 0.75rem #c60c0e26",
    border: "none",
  },

  "& .MuiSlider-track": {
    height: "0.3rem",
    backgroundColor: "#c60c0e",
  },
  "& .MuiSlider-rail": {
    height: "0.3rem",
    backgroundColor: "#c60c0e26",
    borderRadius: "1rem",
  },
}));

const RedSEOInput = styled(TextField)(() => ({
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#1e1e1e80",
  },
  "& .MuiOutlinedInput-root:focused": {
    borderColor: "#1e1e1e80",
  },
}));

function GPTPage() {
  const lastValidated = new Date(localStorage.getItem("validationDate"));

  if (Math.abs(new Date(Date.now()) - lastValidated) / 60000 > 30) {
    return <Navigate to="/" />;
  }

  const [applySeo, setApplySeo] = React.useState(false);

  const [seoKeywords] = React.useState({});

  const [keywordList, setKeywordList] = React.useState([]);

  const handleSEOChange = (e, index) => {
    seoKeywords[index] = e.target.value;
    setKeywordList(Object.values(seoKeywords));
  };

  const toggleSeo = () => {
    setApplySeo(!applySeo);
  };

  const [applySimplify, setApplySimplify] = React.useState(false);

  const toggleSimplify = () => {
    setApplySimplify(!applySimplify);
  };

  const [simplifyValue, setSimplifyValue] = React.useState(1);
  const handleSimplifyValueChange = (event, newValue) => {
    setSimplifyValue(newValue);
  };

  if (localStorage.getItem("validationDat") != null) {
    localStorage.setItem("validationDate", "1970-01-01T00:00:00Z");
  }

  const [applyGrammar, setApplyGrammar] = React.useState(false);
  const toggleGrammar = () => {
    setApplyGrammar(!applyGrammar);
  };

  //

  const gptInterface = React.useMemo(
    () => (
      <GPTInterface
        steps={{ Simplify: applySimplify, Grammar: applyGrammar, SEO: applySeo }}
        simplifyLevel={simplifyValue}
        seoKeywords={keywordList}
      />
    ),
    [applyGrammar, applySeo, applySimplify, simplifyValue, seoKeywords, keywordList]
  );

  return (
    <>
      <DefaultNavbar routes={[]} transparent light />
      <MKBox bgColor="#f0f0f0">
        <MKBox
          bgColor="#1e1e1e"
          minHeight="5.5rem"
          width="100%"
          sx={{
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        />
        <MKBox
          bgColor="#e9e9e9"
          minHeight="8rem"
          width="100%"
          sx={{
            display: "grid",
            placeItems: "center",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: 1,
            paddingLeft: "25%",
            paddingRight: "25%",
          }}
        >
          <FormGroup>
            <FormControlLabel
              control={<RedSwitch value={applyGrammar} onChange={toggleGrammar} />}
              label="Grammar"
            />
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              control={<RedSwitch value={applySeo} onChange={toggleSeo} />}
              label="SEO"
            />
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              control={<RedSwitch value={applySimplify} onChange={toggleSimplify} />}
              label="Simplify"
            />
          </FormGroup>
        </MKBox>
        {applySeo && (
          <MKBox
            bgColor="#e9e9e9"
            minHeight="2.5rem"
            width="100%"
            sx={{
              display: "grid",
              placeItems: "center",
              paddingLeft: "5%",
              paddingRight: "5%",
              gridTemplateColumns: "repeat(6, 1fr)",
              paddingBottom: "2rem",
              //  display: isVisible ? "grid" : "none",
            }}
          >
            <Typography className="MuiFormControlLabel-label">SEO</Typography>
            <RedSEOInput label="Keyword 1" key="Keyword1" onChange={(e) => handleSEOChange(e, 1)} />
            <RedSEOInput label="Keyword 2" key="Keyword2" onChange={(e) => handleSEOChange(e, 2)} />
            <RedSEOInput label="Keyword 3" key="Keyword3" onChange={(e) => handleSEOChange(e, 3)} />
            <RedSEOInput label="Keyword 4" key="Keyword4" onChange={(e) => handleSEOChange(e, 4)} />
            <RedSEOInput label="Keyword 5" key="Keyword5" onChange={(e) => handleSEOChange(e, 5)} />
          </MKBox>
        )}
        {applySimplify && (
          <MKBox
            bgColor="#e9e9e9"
            minHeight="2.5rem"
            width="100%"
            sx={{
              display: "grid",
              placeItems: "center",
              gap: 1,
              paddingLeft: "5%",
              paddingRight: "5%",
              gridTemplateColumns: "repeat(6, 1fr)",
              paddingBottom: "2rem",
              //  display: isVisible ? "grid" : "none",
            }}
          >
            <Typography className="MuiFormControlLabel-label">Simplify</Typography>
            <RedSlider
              value={simplifyValue}
              min={1}
              max={5}
              step={1}
              onChange={handleSimplifyValueChange}
              aria-labelledby="input-slider"
            />
            <Typography className="MuiFormControlLabel-Value">Level {simplifyValue}</Typography>
          </MKBox>
        )}
        {gptInterface}
        <Footer />
      </MKBox>
    </>
  );
}

export default GPTPage;
