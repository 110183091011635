/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import { Navigate, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Material Kit 2 React example components
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Material Kit 2 React page layout routes
import routes from "routes";

// Images
import bgImage from "assets/images/login_background.png";

// eslint-disable-next-line no-extend-native
String.prototype.hashCode = function () {
  let hash = 0;
  let i;
  let chr;
  if (this.length === 0) return hash;
  // eslint-disable-next-line no-plusplus
  for (i = 0; i < this.length; i++) {
    chr = this.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + chr;
    // eslint-disable-next-line no-bitwise
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

function SignInBasic() {
  const navigate = useNavigate();

  const correctUser = "kleinezeitung";

  const [password, setPassword] = useState("");

  const [pwIncorrect, setPwIncorrect] = useState(false);

  const handlePasswordChange = (e) => setPassword(e);

  const [email, setEmail] = useState("");

  const handleEmailChange = (e) => setEmail(e);

  const lastValidated = new Date(localStorage.getItem("validationDate"));

  if ((new Date(Date.now()) - lastValidated) / 60000 < 30) {
    return <Navigate to="/gpt" />;
  }

  const handleLogIn = () => {
    console.log(localStorage.getItem("password"));
    console.log(password.hashCode());
    if (
      password.hashCode().toString() === localStorage.getItem("password") &&
      email === correctUser
    ) {
      console.log("pw correct");
      setPwIncorrect(false);
      localStorage.setItem("validationDate", new Date(Date.now()).toString());
      navigate("/gpt");
    } else {
      setPwIncorrect(true);
    }
  };
  return (
    <>
      <DefaultNavbar routes={routes} transparent light />
      <MKBox
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MKBox px={1} width="100%" height="100vh" mx="auto" position="relative" zIndex={2}>
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <Card>
              <MKBox
                variant="gradient"
                bgColor="error"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  Sign in
                </MKTypography>
              </MKBox>
              <MKBox pt={4} pb={3} px={3}>
                <MKBox component="form" role="form">
                  <MKBox mb={2}>
                    <MKInput
                      type="text"
                      label="User"
                      fullWidth
                      onChange={(e) => handleEmailChange(e.target.value)}
                    />
                  </MKBox>
                  <MKBox mb={2}>
                    <MKInput
                      type="password"
                      label="Password"
                      fullWidth
                      onChange={(e) => handlePasswordChange(e.target.value)}
                    />
                  </MKBox>
                  {pwIncorrect && (
                    <MKBox mb={2}>
                      <MKTypography variant="body2" color="error">
                        Password incorrect
                      </MKTypography>
                    </MKBox>
                  )}
                  <MKBox mt={4} mb={1}>
                    <MKButton variant="gradient" color="error" fullWidth onClick={handleLogIn}>
                      log in
                    </MKButton>
                  </MKBox>
                </MKBox>
              </MKBox>
            </Card>
          </Grid>
        </Grid>
      </MKBox>
    </>
  );
}

export default SignInBasic;
